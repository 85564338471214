<template>
  <el-checkbox-group v-model="dictValue">
    <el-checkbox v-for="item in list" :key="item.value" :label="item.value">{{ item.name }}</el-checkbox>
  </el-checkbox-group>
</template>

<script>
import { getDictEnumListAPI } from '@/enum/dict.js'
export default {
  name: 'SelectDict',

  props: {
    value: {
      type: [String, Array],
      default: ''
    },
    dictCode: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: '500px'
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    dictValue: {
      get() {
        return this.value
      },

      set(val) {
        this.$emit('input', val)
      }
    }
  },

  data() {
    return {
      list: [],
      isLoading: false
    }
  },

  created() {
    this.getList('')
  },

  methods: {
    async getList(query) {
      // let params = {
      //   page: 1,
      //   pageSize: 20,
      //   name: query
      // }
      let res = await getDictEnumListAPI(this.dictCode)
      this.list = res
    }
  }
}
</script>

<style lang="scss" scoped>
.el-select /deep/ {
  .el-select__caret:first-child::before {
    content: '\e6e1';
  }
  .is-focus {
    .el-select__caret:first-child {
      transform: rotateZ(0deg);
    }
  }
}
</style>
